import React from "react";
import { Container } from "shards-react";

const Documentation = () => {
  return (
    <Container fluid className="main-content-container px-4">
      Documentation
    </Container>
  );
};

export default Documentation;
