import React from "react";
import { Container, Col } from "shards-react";
import {useParams} from 'react-router-dom'
import {modules} from '../../../../modules/modulesContent'
import { useTranslation } from "react-i18next";

export default () => {
  const {module} = useParams()
  const { t } = useTranslation();


  const getModuleTitle = () => {
    return modules.filter(element =>{
      return element.route===module;
    })[0].displayName
  }


  return(
    <Container>
    <Col>
      <h5 >{(module && getModuleTitle())||t("pagina-inicial")}</h5>
    </Col>
  </Container>
  )
};
