import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "shards-react";
import React from "react";
 


const exportPDF = (body,colums,title,fileName) => {

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const headers = [colums()];

    const data = body();

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(fileName)
  }

  const PdfExporter = ({tableData, tableColums,fileName,fileTitle}) => {

    return(
      <Button theme="danger" className="pi pi-file-pdf" onClick={() => exportPDF(tableData, tableColums,fileTitle,`Tabela ${fileName}`)}>
      </Button>
    )
  }


  export {PdfExporter}